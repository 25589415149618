import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FooterComponent } from '../../../components/footer/footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { CommonHeaderComponent } from '../../../components/common-header/common-header.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-alfred',
  standalone: true,
  imports: [
    RouterLink,
    FooterComponent,
    StaticSearchComponent,
    CommonHeaderComponent,
  ],
  templateUrl: './alfred.component.html',
  styleUrl: './alfred.component.scss',
})
export class AlfredComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('alfred');
  }
}
