<section class="award">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-12">
        <div class="text-lg-start text-center mb-3 mb-sm-0">
          <h2 class="display-5 fw-bold">Our awards</h2>
          <p class="h4 fw-light mb-3">
            2023 & 2024 Multiple Travel Insurance Awards
          </p>
        </div>
      </div>
      <div class="col-lg-9">
        <div class="awardflex pt-2 pt-sm-0">
          <div>
            <div class="text-center">
              <a
                [routerLink]="['/information']"
                [queryParams]="{ pageName: 'world2coverawards' }"
                [queryParamsHandling]="'merge'"
                class="d-md-flex"
              >
                <img class="" style="margin-right: 6px;" src="assets/images/w2c/aw-new-001.png" />
                <img class="" style="margin-left: 6px;" src="assets/images/w2c/aw-new-002.png" />
              </a>
            </div>
            <div class="text-center mt-3">
              <p class="fw-bold font-14">
                International Outstanding Value 2022 - 2024 <br />
                Cruise Outstanding Value 2024 <br />
                Seniors Outstanding Value 2017-2019 <br />
              </p>
            </div>
          </div>
          <div>
            <div class="text-center d-md-flex" >
              <a href="https://mozo.com.au/expertschoice/travel-insurance" class="d-md-flex">
                <img class="" src="assets/images/w2c/aw003.png" />
                <img class="" src="assets/images/w2c/mozo025.png" />
              </a>
              <a
                href="https://www.wemoney.com.au/wemoney-travel-awards-2024-winners"
              >
                <img class="" src="assets/images/w2c/WeMoney.png" />
              </a>
            </div>

            <div class="text-center mt-3">
              <p class="fw-bold font-14">
                Travel Insurance Company of the Year 2023 - 2024 <br />
                Exceptional Value Comprehensive Travel Insurance 2023 - 2025 <br />
                Exceptional Quality Travel Insurance 2023 - 2024 <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
