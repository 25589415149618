<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header [activeHeader]="'TravelAlerts'"></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/w2c/travel-alerts-banner.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Travel Alerts</h1>
      <p>Important Travel Insurance Information before to buy your policy</p>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-md-4">
          <div class="page-content-info">
            <h3>Active Traveller Alerts</h3>
            <p>
              Worldwide events may impact coverage for new travel insurance
              purchases, or affect your travel plans for policies already
              purchased. Please review all travel alerts related to your area of
              travel, and the important information contained in them.
            </p>
            <ul class="text-gray link">
              <li>
                <a target="_blank" href="/alfred"
                  >Tropical Cyclone Alfred</a
                >
              </li>
              <li>
                <a target="_blank" href="/israel"
                  >Israel and the Occupied Palestinian Territories</a
                >
              </li>
              <li>
                <a target="_blank" href="/israelfaqs"
                  >FAQs for Israel and OPT</a
                >
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Inactive Traveller Alerts</h3>
            <p>
              The alerts below are alerts that we have published in the past
              that are no longer active.
            </p>
            <ul class="link text-gray">
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'donottravel' }"
                  [queryParamsHandling]="'merge'"
                  >'Do Not Travel'
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'covid-19' }"
                  [queryParamsHandling]="'merge'"
                >
                  COVID-19</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'virginairline' }"
                  [queryParamsHandling]="'merge'"
                  >Virgin Airline</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'chinaandirandonottravel' }"
                  [queryParamsHandling]="'merge'"
                  >China and Iran - Do Not Travel</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'hongkongprotests' }"
                  [queryParamsHandling]="'merge'"
                  >Hong Kong Protests</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'fijiscyclonesarai' }"
                  [queryParamsHandling]="'merge'"
                  >Fiji's Cyclone Sarai</a
                >
              </li>
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'indonesiasmtagungvolcano' }"
                  [queryParamsHandling]="'merge'"
                  >Indonesia’s Mt Agung volcano</a
                >
              </li>
            </ul>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</div>
