<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-common-header></app-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img src="assets/images/w2c/page-header/faqs.jpg" alt="" class="w-100 page-title-image" />
    <div class="page-header-title-caption">
      <h1>Tropical Cyclone Alfred</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="page-content-info">
            <h3>Tropical Cyclone Alfred – travel disruptions to and from South Eastern Queensland and Northern New South
              Wales, effective 7pm (AEST) on 3rd March 2025</h3>
            <p>
              As a result of Tropical Cyclone Alfred, the system, which is expected to remain at the higher end of
              category 2, is set to make landfall in the coming days, potentially bringing significant rainfall to areas
              of Queensland and Northern New South Wales, as well as the high risk of flooding, high-speed winds, storm
              surge and coastal inundation. Please check with your travel services provider if your travel plans have
              been affected due to Tropical Cyclone Alfred’s activity.
            </p>
            <p>
              If you have a travel insurance policy with us and have been impacted by this event, please contact our
              claims team. The contact details are located on the website under 'How to Claim' You have the option to
              contact the claims team by phone or lodge a claim using the online portal.
            </p>

          </div>

          <div class="page-content-info">
            <p><b>Please note that you will only be covered for this event if you have purchased a policy before 7pm
              (AEST) on 3rd March 2025.</b></p>
            <p>
              If you have purchased a policy after the above time and are intending to travel while this event continues
              to disrupt travel, you will not be covered for anything related to this event, in accordance with the
              terms of your travel insurance policy.
            </p>
            <p>
              We will continue to monitor the situation and may update this travel advice.
            </p>
          </div>
            <div class="page-content-info"></div>

            <div class="page-content-info"></div>

            <div class="page-content-info"></div>

            <div class="page-content-info"></div>
          </div>
        </div>
      </div>
  </main>
  <app-footer></app-footer>
</div>