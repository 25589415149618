import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { SegmentService } from '../../services/segment.service';
import { PDSService } from '../../services/pds.service';
import { LocalCacheService } from '../../services/localCache.service';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, CommonModule, TooltipModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  dynamicClassName: string = '';
  tmdLink: string = '';
  env = environment.envName;
  partnerCode = '';
  constructor(
    private router: Router,
    private segmentService: SegmentService,
    private pdsService: PDSService,
    private localCacheService: LocalCacheService,
  ) {}

  ngOnInit() {
    let path = this.router.url.split('/')?.filter(Boolean)?.join('-');
    if (!path) {
      path = 'homepage';
    }
    this.dynamicClassName = path;

    const currentLatestTMD =
      this.localCacheService.getSessionStorage('lastestTMD');
    this.partnerCode =
      this.localCacheService.getSessionStorage('vendorDetail')?.partnerCode;
    if (!currentLatestTMD) {
      this.pdsService.getLatestTMD().subscribe((data) => {
        if (data && data.tmd && data.tmd.length > 0) {
          data.tmd.forEach((obj: any) => {
            if (obj.isActive) {
              this.tmdLink = obj.url;
            }
          });
        }
      });
    } else {
      this.tmdLink = currentLatestTMD?.url;
    }
  }

  triggerSegmentEvent(field: string) {
    let eventNameTitle: any;
    switch (field) {
      case 'pds_footer_1':
        eventNameTitle = 'footer_pds_1';
        break;
      case 'pds_footer_2':
        eventNameTitle = 'footer_pds_2';
        break;
      case 'pds_footer_3':
        eventNameTitle = 'footer_pds_3';
        break;
      case 'tmd_footer_1':
        eventNameTitle = 'footer_tmd_1';
        break;
      case 'tmd_footer_2':
        eventNameTitle = 'footer_tmd_2';
        break;
      case 'tmd_footer_3':
        eventNameTitle = 'footer_tmd_3';
        break;
      case 'travelInsurancePds':
        eventNameTitle = 'footer_travel_insurance_pds';
        break;
    }

    const pdsAndTmdOnClick = {
      eventName: eventNameTitle,
    };

    this.segmentService.segmentIdentifyEventNameAndValue(pdsAndTmdOnClick);
  }
}
