<section class="footer" [ngClass]="dynamicClassName">
  <div class="container-xl footer-top pb-5 mb-5">
    <div class="row ps-lg-5 ps-2 pe-lg-5 pe-2">
      <div class="col-lg-3 col-md-6 pe-0 pe-sm-5">
        <div class="logofooter pe-3 mb-3 mb-md-5">
          <a href="#"
            ><img class="img-fluid" src="assets/images/w2c/lightlogo.png"
          /></a>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 mb-4 mbextamargin">
        <h3 class="text-light fz-22 mb-4">Contact Us</h3>
        <h2 class="display-6 fw-bold text-primary text-left mb-3">
          +61 2 9225 7599
        </h2>
        <p class="text-light mb-0">Monday to Friday, 8am to 9pm AEST</p>
        <p class="text-light">Saturday and Sunday, 9am to 6pm AEST</p>
        <p class="text-light">
          Email: <br /><a
            class="text-decoration-none"
            href="mailto:travelservice@world2cover.com.au"
            ><span class="text-primary"
              >travelservice&#64;world2cover.com.au</span
            ></a
          >
        </p>
        <p class="text-light">
          Address: <br /><span class="text-primary"
            >World2Cover, GPO Box 4616, SYDNEY NSW 2001</span
          >
        </p>
      </div>
      <div class="col-lg-2 col-md-6 col-6">
        <h3 class="text-light mb-4">About</h3>
        <ul class="list-unstyled footerlink">
          <li>
            <a
              routerLink="/about-us"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >About Us</a
            >
          </li>
          <li>
            <a
              [routerLink]="['/information']"
              [queryParams]="{ pageName: 'world2coverawards' }"
              [queryParamsHandling]="'merge'"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >World2Cover Awards</a
            >
          </li>
        </ul>
      </div>
      <div class="col-lg-2 col-md-6 col-6">
        <h3 class="text-light mb-4">Guides</h3>
        <ul class="list-unstyled footerlink">
          <li>
            <a
              routerLink="/refunds"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >Refunds</a
            >
          </li>
          <li>
            <a
              routerLink="/pds"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              (click)="triggerSegmentEvent('travelInsurancePds')"
              >Travel Insurance PDS</a
            >
          </li>
          <li>
            <a
              routerLink="/terms-and-conditions"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >Terms & Conditions</a
            >
          </li>
          <li>
            <a
              routerLink="/privacy-policy"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >Privacy Policy</a
            >
          </li>
          <li>
            <a
              routerLink="/contact-us"
              target="_blank"
              class="text-decoration-none text-primary mb-2 fw-normal"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="container-xl footer-bottom">
    <div class="row ps-md-5 ps-2 pe-md-5 pe-2">
      <p *ngIf="partnerCode !== 'Klook'" class="text-light text-small">
        Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291 AFSL
        246 548) is the issuer of World2Cover Travel Insurance. Any advice set
        out above is general in nature only, and does not take into account your
        objectives, financial situation or needs. Before purchasing any travel
        products, we recommend reviewing the
        <a
          routerLink="/pds"
          target="_blank"
          class=""
          (click)="triggerSegmentEvent('pds_footer_1')"
          >World2Cover Product Disclosure Statement (PDS)</a
        >
        and the
        <a
          href="{{ tmdLink }}"
          class=""
          target="_blank"
          (click)="triggerSegmentEvent('tmd_footer_1')"
          >Target Market Determinations (TMDs)</a
        >
        that apply to these products. Please read the
        <a
          routerLink="/pds"
          target="_blank"
          class=""
          (click)="triggerSegmentEvent('pds_footer_2')"
          >World2Cover Combined Financial Services Guide and Product Disclosure
          Statement</a
        >
        for full details of terms and conditions, limits (including sub-limits)
        and exclusions apply to each of our policies and the different benefits.
        TMDs outline the intended class of customers that comprise the target
        market for these travel products, including key attributes of the
        insurance cover and eligibility criteria. A TMD does not replace the
        terms and conditions, nor the disclosures make, in a PDS so you should
        read the applicable PDS carefully and contact us if you have any
        queries. A copy of our PDS, Supplementary
        <a
          routerLink="/pds"
          target="_blank"
          class=""
          (click)="triggerSegmentEvent('pds_footer_3')"
          >PDS</a
        >
        and
        <a
          href="{{ tmdLink }}"
          class=""
          target="_blank"
          (click)="triggerSegmentEvent('tmd_footer_2')"
          >TMD's</a
        >
        for each travel product can be found in
        <a
          href="{{ tmdLink }}"
          class=""
          target="_blank"
          (click)="triggerSegmentEvent('tmd_footer_3')"
          >here</a
        >.
      </p>
      <p *ngIf="partnerCode === 'Klook'" class="text-light text-small"></p>
      <p class="desclaimer text-light">
        <small>
          ** $unlimited means that generally there is no cap on the maximum
          dollar amount which may be paid out for this benefit, subject to the
          specific terms and conditions, sublimit and exclusions that apply to
          this benefit. This benefit covers reasonable overseas medical and
          hospital costs as a result of an injury (including arising from a
          Terrorist Act) or illness occurring which first shows itself during
          Your Period of Insurance. Benefits may be paid up to 12 months from
          the time You received treatment for the injury or illness, but only
          for reasonable expenses incurred during that time. All medical
          treatments must be provided by Your Treating Doctor or Our Consulting
          Medical Officer. You must notify Us as soon as practicable of Your
          admittance to hospital.
        </small>
      </p>
    </div>
  </div>
</section>
